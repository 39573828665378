<template>
  <div>
    <h4>Investimentos</h4>
    <highcharts
      style="margin-left: 2rem"
      :options="investmentChart"
      v-if="investmentChart.series[0].data.length > 0"
    />

    <div v-else>
      O cliente não possui dados de
      <strong> Investimentos </strong>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'Graphic1',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  computed: {
    investmentChart() {
      const investmentChart = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          width: 1000
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            startAngle: -90,
            endAngle: -90,
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [
          {
            name: 'Total',
            colorByPoint: true,
            data: [],
            dataLabels: { style: { fontSize: '16px' } }
          }
        ],
        colors: ['#203664', '#2f5597', '#8faadc']
      };

      const data = this.diagnosis.graficos.grafico_1;

      const dataFormat = data.map((item) => {
        return [item.nome, item.valor];
      });

      investmentChart.series[0].data = dataFormat;

      return investmentChart;
    }
  }
};
</script>
