<template>
  <div>
    <h4>Reserva de emergência</h4>
    <highcharts
      style="margin-left: 2rem"
      :options="chartEmergencyReserve"
      v-if="chartEmergencyReserve.series.length > 0"
    />
    <div v-else>
      O cliente não possui dados de
      <strong> Reserva de emergência </strong>
    </div>
    <p style="padding: 2rem">
      É um montante financeiro que deve ser acumulado para ultilização em
      situações emergenciais. Essa quantia trará tranquilidade para suprir as
      despesas mensais e continuar mantendo a qualidade de vida
    </p>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'EmergencyReserve',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  computed: {
    chartEmergencyReserve() {
      const chartEmergencyReserve = {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [' ']
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(this.y);
              }
            },
            enableMouseTracking: true
          }
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  enabled: false
                }
              }
            }
          ]
        },
        colors: ['#25a47f', '#203864', '#ffc000']
      };

      const data = this.diagnosis.graficos.reserva_emergencia;
      const dataFormat = data.map((item) => {
        return {
          name: ` R$ ${new Intl.NumberFormat(
            'pt-BR',
            `${{
              style: 'currency',
              currency: 'BRL'
            }}`
          ).format(item.valor)} <br/> ${item.nome} <br/> ${item.meses} Meses `,
          data: [item.valor],
          type: 'column'
        };
      });

      chartEmergencyReserve.series = dataFormat;

      return chartEmergencyReserve;
    }
  }
};
</script>
