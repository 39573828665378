<template>
  <i class="fa fa-medkit" :style="{ color: color ? color : '#fff' }" />
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
