<template>
  <svg
    id="exchange"
    xmlns="http://www.w3.org/2000/svg"
    width="17.198"
    height="19.666"
    viewBox="0 0 17.198 19.666"
  >
    <path
      id="Caminho_674"
      data-name="Caminho 674"
      d="M9.05,7.817v.989a1.5,1.5,0,0,0,.186.783.885.885,0,0,0,.769.446,1.32,1.32,0,0,0,.921-.451l3.266-3.266a1.841,1.841,0,0,0,0-2.6L10.927.451A1.32,1.32,0,0,0,10.006,0C9.53,0,9.05.38,9.05,1.228v.9A9.7,9.7,0,0,0,.005,10.617a.576.576,0,0,0,1.037.41A8.129,8.129,0,0,1,9.05,7.817Zm0,0"
      transform="translate(-0.001 0)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_675"
      data-name="Caminho 675"
      d="M78.618,218.806a.576.576,0,0,0-.677.2,8.128,8.128,0,0,1-8.008,3.21v-.989c0-.848-.48-1.228-.956-1.228a1.32,1.32,0,0,0-.921.451l-3.266,3.266a1.841,1.841,0,0,0,0,2.6l3.266,3.266a1.32,1.32,0,0,0,.921.451c.476,0,.956-.38.956-1.228v-.9a9.7,9.7,0,0,0,9.045-8.49A.576.576,0,0,0,78.618,218.806Zm0,0"
      transform="translate(-61.785 -210.362)"
      :fill="color ? color : '#f8f8fb'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
