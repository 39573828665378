<template>
  <svg
    id="money"
    xmlns="http://www.w3.org/2000/svg"
    width="19.302"
    height="15.03"
    viewBox="0 0 19.302 15.03"
  >
    <circle
      id="Elipse_104"
      data-name="Elipse 104"
      cx="0.364"
      cy="0.364"
      r="0.364"
      transform="translate(3.295 9.278)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_670"
      data-name="Caminho 670"
      d="M172,222.158a4,4,0,1,0,4,4A4,4,0,0,0,172,222.158Zm.018,3.636a1.454,1.454,0,0,1,.412,2.844.092.092,0,0,0-.066.087v.328a.372.372,0,0,1-.339.375.364.364,0,0,1-.388-.363v-.339a.092.092,0,0,0-.064-.088,1.457,1.457,0,0,1-1.027-1.363.375.375,0,0,1,.347-.39.364.364,0,0,1,.38.363.727.727,0,1,0,.727-.727,1.454,1.454,0,0,1-.43-2.844.092.092,0,0,0,.066-.087v-.328a.372.372,0,0,1,.339-.375.364.364,0,0,1,.388.363v.339a.092.092,0,0,0,.064.088,1.457,1.457,0,0,1,1.027,1.363.375.375,0,0,1-.347.39.364.364,0,0,1-.38-.363.727.727,0,1,0-1.454.008A.742.742,0,0,0,172.017,225.794Z"
      transform="translate(-162.348 -217.307)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_671"
      data-name="Caminho 671"
      d="M18.4,174.158H.9a.9.9,0,0,0-.9.892v8.324a.9.9,0,0,0,.9.892H18.4a.9.9,0,0,0,.9-.892V175.05A.9.9,0,0,0,18.4,174.158ZM6.333,183.077H2.413a.3.3,0,0,1-.3-.3.6.6,0,0,0-.605-.6.3.3,0,0,1-.3-.3v-5.351a.3.3,0,0,1,.3-.3.6.6,0,0,0,.605-.6.3.3,0,0,1,.3-.3h3.91a.307.307,0,0,1,.311.277.3.3,0,0,1-.3.317H2.76a.113.113,0,0,0-.107.076,1.2,1.2,0,0,1-.766.755.111.111,0,0,0-.078.105v4.666a.111.111,0,0,0,.078.106,1.2,1.2,0,0,1,.766.755.113.113,0,0,0,.107.076H6.323a.307.307,0,0,1,.311.277.3.3,0,0,1-.3.317Zm-1.81-3.865a.9.9,0,1,1-.9-.892A.9.9,0,0,1,4.524,179.212Zm5.127,3.865a3.865,3.865,0,1,1,3.921-3.865A3.9,3.9,0,0,1,9.651,183.077Zm8.444-1.189a.3.3,0,0,1-.3.3.6.6,0,0,0-.605.6.3.3,0,0,1-.3.3h-3.91a.307.307,0,0,1-.311-.277.3.3,0,0,1,.3-.317h3.573a.113.113,0,0,0,.107-.076,1.2,1.2,0,0,1,.766-.755.111.111,0,0,0,.078-.105v-4.666a.111.111,0,0,0-.078-.105,1.2,1.2,0,0,1-.766-.755.113.113,0,0,0-.107-.076H12.979a.307.307,0,0,1-.311-.277.3.3,0,0,1,.3-.317h3.921a.3.3,0,0,1,.3.3.6.6,0,0,0,.605.6.3.3,0,0,1,.3.3v5.351Zm-3.317-2.676a.9.9,0,1,1,.9.892A.9.9,0,0,1,14.778,179.212Z"
      transform="translate(0 -169.236)"
      :fill="color ? color : '#f8f8fb'"
    />
    <circle
      id="Elipse_105"
      data-name="Elipse 105"
      cx="0.364"
      cy="0.364"
      r="0.364"
      transform="translate(15.279 9.278)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_672"
      data-name="Caminho 672"
      d="M163.5,113.182a1.09,1.09,0,0,0-1.233-.945l-13.888,1.986a.045.045,0,0,0,.006.09h15.153a.091.091,0,0,0,.09-.1Z"
      transform="translate(-145.569 -110.119)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_673"
      data-name="Caminho 673"
      d="M166.764,67.341a.091.091,0,0,0,.075-.112l-.14-.561a1.09,1.09,0,0,0-1.345-.788l-11.428,3.205a.045.045,0,0,0,.018.089l12.82-1.834Z"
      transform="translate(-150.864 -65.843)"
      :fill="color ? color : '#f8f8fb'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
