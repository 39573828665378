<template>
  <svg
    id="cardiogram"
    xmlns="http://www.w3.org/2000/svg"
    width="16.699"
    height="15.068"
    viewBox="0 0 16.699 15.068"
  >
    <path
      id="Caminho_668"
      data-name="Caminho 668"
      d="M8.345,26.392A4.9,4.9,0,0,0,.816,32.573h3.6l.64-1.067a.489.489,0,0,1,.832-.011l1.351,2.122,1.969-4.156a.49.49,0,0,1,.873-.023l1.688,3.134h4.106a4.9,4.9,0,0,0-7.529-6.181Z"
      transform="translate(0 -25.006)"
      :fill="color ? color : '#fff'"
    />
    <path
      id="Caminho_669"
      data-name="Caminho 669"
      d="M58.2,203.8l-1.368-2.54L54.9,205.346a.489.489,0,0,1-.855.053l-1.4-2.2-.375.626a.489.489,0,0,1-.419.237h-3.1c.1.1-.421-.415,6.41,6.38a.489.489,0,0,0,.69,0c6.726-6.691,6.313-6.278,6.41-6.38H58.632A.489.489,0,0,1,58.2,203.8Z"
      transform="translate(-47.156 -195.515)"
      :fill="color ? color : '#fff'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
