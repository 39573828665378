<template>
  <b-row class="d-flex flex-column mt-3 ml-3">
    <h5>DADOS DE CONTATO:</h5>
    <b-col cols="12" lg="10" class="col-xxl-6">
      <b-table-simple class="vertical-table">
        <b-tbody>
          <b-tr>
            <b-th>Nome completo</b-th>
            <b-td>{{ diagnosis.pessoa.pessoa_nome || ' - ' }}</b-td>
          </b-tr>

          <b-tr>
            <b-th>Telefone</b-th>
            <b-td>{{ diagnosis.pessoa.pessoa_telefone || ' - ' }}</b-td>
          </b-tr>

          <b-tr>
            <b-th>Celular</b-th>
            <b-td>{{ diagnosis.pessoa.pessoa_celular || ' - ' }}</b-td>
          </b-tr>

          <b-tr>
            <b-th>E-mail</b-th>
            <b-td>{{ diagnosis.pessoa.pessoa_email || ' - ' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ContactDataTable',
  props: {
    diagnosis: Object
  }
};
</script>

<style lang="scss" scoped>
.vertical-table th {
  background-color: var(--light-grey-2);
}

.vertical-table th,
.vertical-table td {
  border: 1px solid var(--border-grey);
}
</style>
