<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.577"
    height="15.08"
    viewBox="0 0 15.577 15.08"
  >
    <g id="house-black-silhouette-without-door" transform="translate(0.001 0)">
      <path
        id="Caminho_676"
        data-name="Caminho 676"
        d="M15.382,12.248a.743.743,0,0,0-.061-1.063L8.359,5.094a.868.868,0,0,0-1.132.013L.242,11.51a.738.738,0,0,0-.034,1.061l.175.182a.764.764,0,0,0,1.048.08l.522-.468v6.844a.76.76,0,0,0,.76.76H5.437a.76.76,0,0,0,.76-.76V14.422H9.67v4.788a.718.718,0,0,0,.714.76h2.886a.76.76,0,0,0,.76-.76V12.463l.322.283c.178.156.551.031.833-.28Z"
        transform="translate(-0.001 -4.89)"
        :fill="color ? color : '#f8f8fb'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
