<template>
  <svg
    id="dreaming-in-cloud"
    xmlns="http://www.w3.org/2000/svg"
    width="15.577"
    height="16.03"
    viewBox="0 0 15.577 16.03"
  >
    <circle
      id="Elipse_102"
      data-name="Elipse 102"
      cx="1.591"
      cy="1.591"
      r="1.591"
      transform="translate(9.328 11.241)"
      :fill="color ? color : '#f8f8fb'"
    />
    <circle
      id="Elipse_103"
      data-name="Elipse 103"
      cx="1.158"
      cy="1.158"
      r="1.158"
      transform="translate(13.26 13.714)"
      :fill="color ? color : '#f8f8fb'"
    />
    <path
      id="Caminho_667"
      data-name="Caminho 667"
      d="M29.243,6.67A2.441,2.441,0,0,0,27.3,4.281.329.329,0,0,1,27.035,4a4.556,4.556,0,0,0-8.69-1.279.332.332,0,0,1-.381.187,3.177,3.177,0,0,0-.79-.094A3.15,3.15,0,0,0,15.4,8.543a2.169,2.169,0,0,0,3.058.955.331.331,0,0,1,.456.118,3.243,3.243,0,0,0,5.2.58.334.334,0,0,1,.43-.047A1.608,1.608,0,0,0,26.961,9.3a.328.328,0,0,1,.255-.223A2.438,2.438,0,0,0,29.243,6.67Z"
      transform="translate(-14.051)"
      :fill="color ? color : '#f8f8fb'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
