<template>
  <b-modal v-model="isOpenModal" ok-only>
    <template #modal-header>
      <div style="margin: 0 auto">
        <h5 style="color: var(--grey)">
          {{ title }}
        </h5>
      </div>
    </template>

    <template #modal-footer="{ ok }">
      <b-button size="md" variant="success" @click="ok()">OK</b-button>
    </template>

    <div
      class="p-3"
      style="border-color: var(--light-grey) !important"
      v-if="diagnosisComment"
    >
      {{ diagnosisComment.comentario }}
      <div class="text-muted" v-if="!diagnosisComment.comentario">
        Sem comentário
      </div>

      <div v-if="diagnosisComment.download_url" class="mt-3">
        <div>
          <p class="mb-0">{{ diagnosisComment.nome_original }}</p>
          <a :href="diagnosisComment.download_url" target="_blank">
            Visualizar
          </a>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalViewComment',
  props: {
    title: {
      type: String,
      default: 'Comentário'
    },
    isOpen: Boolean,
    diagnosisComment: Object
  },
  methods: {
    ok() {
      this.cancel();
    },
    cancel() {
      this.$emit('closeModal');
    }
  },
  computed: {
    isOpenModal: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      }
    }
  }
};
</script>
