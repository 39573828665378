<template>
  <div>
    <h4>Liberdade financeira</h4>
    <highcharts
      style="margin-left: 2rem"
      :options="chart"
      v-if="chart.series.length > 0"
    />
    <div v-else>
      O cliente não possui dados de
      <strong>Liberdade financeira</strong>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'FinancialFreedom',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  computed: {
    chart() {
      const chart = {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [' ']
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(this.y);
              }
            },
            enableMouseTracking: true
          }
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  enabled: false
                }
              }
            }
          ]
        },
        colors: ['#203864', '#25a47f', '#2f5597', '#c00000', '#ffc000']
      };

      const data = this.diagnosis.graficos.liberdade_financeira;
      const dataFormat = data.map((item) => {
        return {
          name: ` R$ ${new Intl.NumberFormat(
            'pt-BR',
            `${{
              style: 'currency',
              currency: 'BRL'
            }}`
          ).format(item.valor)} <br/> ${item.nome}`,
          data: [item.valor],
          type: 'column'
        };
      });

      chart.series = dataFormat;

      return chart;
    }
  }
};
</script>
