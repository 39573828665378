<template>
  <svg
    id="shield-2"
    xmlns="http://www.w3.org/2000/svg"
    width="14.683"
    height="17.446"
    viewBox="0 0 14.683 17.446"
  >
    <g id="Grupo_386" data-name="Grupo 386" transform="translate(0 0)">
      <path
        id="Caminho_755"
        data-name="Caminho 755"
        d="M48.572,4.491c-.021-.459-.021-.9-.021-1.335a.615.615,0,0,0-.626-.626A8.383,8.383,0,0,1,41.687.172a.644.644,0,0,0-.876,0A8.383,8.383,0,0,1,34.573,2.53a.615.615,0,0,0-.626.626c0,.438,0,.876-.021,1.335-.083,4.381-.209,10.389,7.114,12.914l.209.042.209-.042C48.76,14.88,48.655,8.893,48.572,4.491Zm-7.823,6.071a.673.673,0,0,1-.438.167H40.29a.587.587,0,0,1-.438-.209l-1.94-2.149.939-.834,1.523,1.69,3.38-3.213.855.918Z"
        transform="translate(-33.908 0)"
        :fill="color ? color : '#f8f8fb'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: [String, Boolean]
  }
};
</script>
