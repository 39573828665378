<template>
  <div>
    <h4>Previdência</h4>
    <template v-if="pensionChart.series[0].data.length > 0">
      <highcharts style="margin-left: 1rem" :options="pensionChart" />

      <p style="padding: 2rem">
        Percentual de participação entre o PGBL e VGBL.
      </p>
      <div>
        Tipo de declaração:
        {{
          diagnosis.pessoa.pessoa_despesa.tipo_declaracao_ir ||
          '(Não informado)'
        }}
      </div>
      <div>
        Oportunidade de PGBL R$
        {{
          diagnosis.indicadores_financeiros.oportunidade_pgbl.value | decimal
        }}
      </div>
    </template>
    <div v-else>
      O cliente não possui dados de
      <strong> Previdência </strong>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'Pension',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  computed: {
    pensionChart() {
      const pensionChart = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [],
            dataLabels: { style: { fontSize: '18px' } }
          }
        ],
        colors: ['#4472c4', '#a5a5a5']
      };

      const dataSeriesPensionChartPrevidencia = this.diagnosis.graficos.previdencia.map(
        (item, index) => {
          if (index === 0) {
            return {
              sliced: false,
              selected: true,
              name: !item.nome ? 'Nome não informado' : item.nome,
              y: item.valor
            };
          }
          return {
            name: !item.nome ? 'Nome não informado' : item.nome,
            y: item.valor
          };
        }
      );

      pensionChart.series[0].data = dataSeriesPensionChartPrevidencia;

      return pensionChart;
    }
  }
};
</script>
