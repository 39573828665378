<template>
  <b-row style="margin-bottom: 1rem">
    <b-col
      v-if="chartNetInvestiments.series[0].data.length > 0"
      style="background: var(--white); border-radius: 5px; padding: 2rem"
    >
      <h4 style="margin-bottom: 2rem">
        Projeção para Renda Fixa, Multimercado e Renda Variável acima da
        inflação
      </h4>
      <b-row>
        <b-col cols="12" md="3">
          <table class="table">
            <tr class="bg-secondary text-white">
              <th>ALOCAÇÃO</th>
              <th></th>
            </tr>
            <tr style="background: var(--border-grey)">
              <th>% Atual</th>
              <th></th>
            </tr>
            <tr>
              <th>Renda Fixa</th>
              <td>{{ porcentagemRendaFixa | decimal(1) }}%</td>
            </tr>
            <tr>
              <th>Multimercado</th>
              <td>{{ porcentagemMultimercado | decimal(1) }}%</td>
            </tr>
            <tr>
              <th>Renda Variável</th>
              <td>{{ porcentagemRendaVariavel | decimal(1) }}%</td>
            </tr>
            <tr>
              <th>Outros</th>
              <td>{{ porcentagemRendaOutros | decimal(1) }}%</td>
            </tr>
          </table>

          <p>
            Projeção com base em Rentabilidade esperada
            <strong>FIDUC - Longo prazo.</strong>
          </p>
        </b-col>

        <b-col cols="12" md="9">
          <highcharts
            style="margin-bottom: 2rem"
            :options="lineChart"
            v-if="lineChart.series.length > 0"
          />
        </b-col>

        <b-col cols="12" class="col-xxl-9 offset-xxl-3">
          <table class="table">
            <tr style="background: var(--border-grey)">
              <th style="background: var(--white)"></th>
              <th
                v-for="(items, index) in itemsTableProjectionInvestiments"
                :key="index"
              >
                Ano {{ items.ano }}
              </th>
            </tr>
            <tr>
              <th>Carteira atual</th>
              <td
                v-for="(items, index) in itemsTableProjectionInvestiments"
                :key="index"
              >
                {{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(items.carteira_atual)
                }}
              </td>
            </tr>
            <tr>
              <th>Aloc. Atual Fiduc</th>
              <td
                v-for="(items, index) in itemsTableProjectionInvestiments"
                :key="index"
              >
                {{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(items.carteira_atual_fiduc)
                }}
              </td>
            </tr>
            <tr>
              <th>Aloc. Sugerida Fiduc</th>
              <td
                v-for="(items, index) in itemsTableProjectionInvestiments"
                :key="index"
              >
                {{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(items.carteira_sugerida_fiduc)
                }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-col>
    <div v-else>
      O cliente não possui dados para
      <strong>
        Projeção de Renda Fixa, Multimercado e Renda Variável acima da inflação
      </strong>
    </div>
  </b-row>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'InvestmentProjection',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  data() {
    return {
      porcentagemRendaFixa: 0,
      porcentagemMultimercado: 0,
      porcentagemRendaVariavel: 0,
      porcentagemRendaOutros: 0
    };
  },
  methods: {
    calcPercentCurrentAlocationInEachInvestiment() {
      const netInvestiment = this.diagnosis.graficos.investimentos_liquidos;

      const sumAllInvestments = netInvestiment.reduce((acc, cur) => {
        return acc + cur.valor;
      }, 0);

      netInvestiment.forEach((item) => {
        if (item.tipo === 1) {
          this.porcentagemRendaFixa =
            (item.valor / Number(sumAllInvestments)) * 100;
        }
        if (item.tipo === 2) {
          this.porcentagemMultimercado =
            (item.valor / Number(sumAllInvestments)) * 100;
        }
        if (item.tipo === 3) {
          this.porcentagemRendaVariavel =
            (item.valor / Number(sumAllInvestments)) * 100;
        }
        if (item.tipo === 4) {
          this.porcentagemRendaOutros =
            (item.valor / Number(sumAllInvestments)) * 100;
        }
      });
    }
  },
  computed: {
    chartNetInvestiments() {
      const chartNetInvestiments = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '350',
          width: '450'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [],
            dataLabels: { style: { fontSize: '18px' } }
          }
        ],
        colors: ['#4472c4', '#a5a5a5', '#5b9bd5', '#264478']
      };

      const netInvestiment = this.diagnosis.graficos.investimentos_liquidos;

      const dataSeriesChartNetInvestiments = netInvestiment.map((item) => {
        return {
          name: item.nome,
          y: item.valor
        };
      });

      chartNetInvestiments.series[0].data = dataSeriesChartNetInvestiments;

      return chartNetInvestiments;
    },
    lineChart() {
      const lineChart = {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          }
        },
        series: [
          {
            name: 'Carteira atual',
            data: []
          },
          {
            name: 'Aloc. Atual Fiduc',
            data: []
          },
          {
            name: 'Aloc. Sugerida Fiduc',
            data: []
          }
        ],
        colors: ['#7f7f7f', '#ffc000', '#ed7d31']
      };

      const years = this.diagnosis.graficos.projecao_investimentos.map(
        (item) => {
          return `Ano ${String(item.ano)}`;
        }
      );
      lineChart.xAxis.categories = years;

      this.diagnosis.graficos.projecao_investimentos.forEach((item) => {
        lineChart.series[0].data.push(item.carteira_atual);
        lineChart.series[1].data.push(item.carteira_atual_fiduc);
        lineChart.series[2].data.push(item.carteira_sugerida_fiduc);
      });

      return lineChart;
    },
    itemsTableProjectionInvestiments() {
      return this.diagnosis.graficos.projecao_investimentos;
    }
  },
  mounted() {
    this.calcPercentCurrentAlocationInEachInvestiment();
  }
};
</script>
