<template>
  <b-row class="d-flex mt-3 ml-3">
    <b-col cols="12">
      <h5>DADOS DO CONSULTOR:</h5>
    </b-col>

    <b-col cols="12" lg="10" class="col-xxl-6">
      <p class="d-flex d-xxl-none" v-if="editMode">
        Caso você precise de um especialista para atender seu cliente nesta
        modalidade, por favor preencher os campos abaixo com os dados para que o
        especialista entre em contato com você:
      </p>

      <b-table-simple class="vertical-table">
        <b-tbody>
          <b-tr>
            <b-th>Nome completo</b-th>
            <b-td v-if="!editMode">
              {{ item.consultor_nome || '-' }}
            </b-td>
            <b-td v-else>
              <b-form-input v-model="item.consultor_nome" />
            </b-td>
          </b-tr>

          <b-tr>
            <b-th>Telefone</b-th>
            <b-td v-if="!editMode">
              {{ item.consultor_telefone || '-' }}
            </b-td>
            <b-td v-else>
              <VuePhoneNumberInput
                :translations="numberInputTranslations"
                v-model="item.consultor_telefone"
              />
            </b-td>
          </b-tr>

          <b-tr>
            <b-th>Celular</b-th>
            <b-td v-if="!editMode">
              {{ item.consultor_celular || '-' }}
            </b-td>
            <b-td v-else>
              <VuePhoneNumberInput
                :translations="numberInputTranslations"
                v-model="item.consultor_celular"
              />
            </b-td>
          </b-tr>

          <b-tr>
            <b-th>E-mail</b-th>
            <b-td v-if="!editMode">
              {{ item.consultor_email || '-' }}
            </b-td>
            <b-td v-else>
              <b-form-input v-model="item.consultor_email" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="text-right" v-if="editMode">
        <b-button
          variant="dark-primary"
          style="width: 150px"
          :disabled="loading || !modified"
          @click="handleSave()"
        >
          Salvar
        </b-button>
      </div>
    </b-col>

    <b-col class="d-none d-xxl-flex" v-if="editMode">
      <p>
        Caso você precise de um especialista para atender seu cliente nesta
        modalidade, por favor preencher os campos abaixo com os dados para que o
        especialista entre em contato com você:
      </p>
    </b-col>
  </b-row>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { updateDiagnosisProductItem } from '@/services/requests/diagnosis';

export default {
  name: 'ConsultantDataTable',
  components: {
    VuePhoneNumberInput
  },
  props: {
    item: Object,
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      modified: false
    };
  },
  methods: {
    handleSave() {
      if (this.loading) return;

      this.loading = true;

      const item = this.item;

      updateDiagnosisProductItem(item.diagnostico_produto_item_id, item)
        .then(() => {
          this.loading = false;
          this.modified = false;
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data.statusMessage);
        });
    }
  },
  watch: {
    'item.consultor_nome': function () {
      this.modified = true;
    },
    'item.consultor_telefone': function () {
      this.modified = true;
    },
    'item.consultor_celular': function () {
      this.modified = true;
    },
    'item.consultor_email': function () {
      this.modified = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical-table th {
  background-color: var(--light-grey-2);
  vertical-align: middle;
}

.vertical-table th,
.vertical-table td {
  border: 1px solid var(--border-grey);
}
</style>
