<template>
  <div>
    <h4>Ativos</h4>
    <GChart
      :setting="('current', { packages: ['corechart'] })"
      type="CandlestickChart"
      :data="activeChart"
      :options="activeChartOptions"
    />
    <table class="table">
      <tr style="background: var(--border-grey)">
        <th style="background: var(--white)"></th>
        <th
          v-for="(items, index) in this.diagnosis.graficos.grafico_2"
          :key="index"
        >
          {{ items.nome }}
        </th>
      </tr>
      <tr>
        <th>Valor</th>
        <td
          v-for="(items, index) in this.diagnosis.graficos.grafico_2"
          :key="index"
          :style="items.nome === 'Financiamento & dívidas' && 'color: red'"
        >
          {{
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(items.valor)
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';

export default {
  name: 'Graphic2',
  components: {
    GChart
  },
  props: {
    diagnosis: Object
  },
  data() {
    return {
      activeChartOptions: {
        height: 500,
        width: 1000,
        legend: 'none',
        bar: { groupWidth: '80%' },
        candlestick: {
          fallingColor: { strokeWidth: 0, fill: '#c00000' },
          risingColor: { strokeWidth: 0, fill: '#70ad47' }
        }
      }
    };
  },
  computed: {
    activeChart() {
      const data = this.diagnosis.graficos.grafico_2;

      let totalPreviousValue = 0;

      const dataFormat = data.map((item, index) => {
        let initialValue = totalPreviousValue;
        const finalValue = totalPreviousValue + item.valor;

        totalPreviousValue += item.valor;

        if (index === data.length - 1) {
          initialValue = 0;
        }

        return [item.nome, initialValue, initialValue, finalValue, finalValue];
      });

      const activeChart = [['x', 'y', 'Close', 'Open', 'High'], ...dataFormat];

      return activeChart;
    }
  }
};
</script>
