<template>
  <b-row style="margin-bottom: 1rem">
    <b-col style="background: var(--white); border-radius: 5px; padding: 2rem">
      <h4>Investimentos líquidos</h4>
      <b-row v-if="chartNetInvestiments.series[0].data.length > 0">
        <b-col cols="12" class="col-xxl-6">
          <highcharts
            :options="chartNetInvestiments"
            v-if="chartNetInvestiments.series[0].data.length > 0"
            class="d-flex justify-content-center d-xxl-block"
          />
          <div v-else>
            O cliente não possui dados de
            <strong> Investimentos líquidos </strong>
          </div>
        </b-col>

        <b-col
          cols="12"
          class="col-xxl-6"
          style="display: flex; flex-direction: column"
        >
          <b-table hover :items="items">
            <template #head(nome)>
              <span></span>
            </template>

            <template #head(tipo)>
              <span></span>
            </template>

            <template #head(valor)>
              <span></span>
            </template>

            <template #head(rentabilidade)>
              <span>Rend. UL - 12 Meses</span>
            </template>

            <template #cell(nome)="nome">
              <strong>{{ nome.item.nome }}</strong>
            </template>

            <template #cell(tipo)="tipo">
              <span>{{
                tipo.item.tipo === 1
                  ? 'Renda Fixa'
                  : tipo.item.tipo === 2
                  ? 'Multimercado'
                  : tipo.item.tipo === 3
                  ? 'Renda variável'
                  : tipo.item.tipo >= 4 && 'Outros'
              }}</span>
            </template>

            <template #cell(valor)="valor">
              <span>
                {{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(valor.item.valor)
                }}</span
              >
            </template>

            <template #cell(rentabilidade)="rentabilidade">
              <span
                :style="
                  rentabilidade.item.rentabilidade < 0 && 'color: var(--red)'
                "
              >
                {{ rentabilidade.item.rentabilidade | decimal }}%
              </span>
            </template>

            <template #cell(acima_inflacao)="acima_inflacao">
              <span
                :style="
                  acima_inflacao.item.acima_inflacao < 0 && 'color: var(--red)'
                "
                >{{ acima_inflacao.item.acima_inflacao | decimal }}%</span
              >
            </template>
          </b-table>

          <table class="table">
            <tr>
              <th colspan="4">Rentabilidade média</th>
              <td>{{ averageProfitability.toFixed(2) }}%</td>
              <td>
                <span>{{ (averageProfitability - ipca) | decimal }}%</span>
              </td>
            </tr>
            <tr>
              <th colspan="4">IPCA</th>
              <td></td>
              <td>
                <strong
                  :style="
                    ipca >= 0 ? 'color: var(--green)' : 'color: var(--red)'
                  "
                  >{{ ipca | decimal }}%</strong
                >
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <div v-else>
        O cliente não possui dados de
        <strong> Investimentos líquidos </strong>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'LiquidInvestments',
  components: {
    highcharts: Chart
  },
  props: {
    diagnosis: Object
  },
  data() {
    return {
      averageProfitability: 0,
      ipca: 0
    };
  },
  methods: {
    calcAvarages() {
      /* const netInvestiment = this.diagnosis.graficos.investimentos_liquidos; */

      /* const average_profitability = netInvestiment.reduce((total, atual) => {
        return total + atual.rentabilidade;
      }, 0); */

      /* const average_ipca = netInvestiment.reduce((total, atual) => {
        return total + atual.acima_inflacao;
      }, 0); */

      /* const media = average_profitability / 12; */
      /* const mediaIPCA = average_ipca / 12; */

      /* this.averageProfitability = media; */
      this.ipca = 11.3;
    },
    calcAvarageProfitability() {
      const netInvestiment = this.diagnosis.graficos.investimentos_liquidos;

      const averageNetInvestimentsLast12Months = netInvestiment.map((item) => {
        return {
          valor: item.valor / (1 + item.rentabilidade / 100)
        };
      });

      const sumAllInvestments = netInvestiment.reduce((acc, cur) => {
        return acc + cur.valor;
      }, 0);

      const sumAllInvestmentsWithProft = averageNetInvestimentsLast12Months.reduce(
        (acc, cur) => {
          return acc + cur.valor;
        },
        0
      );

      const percentageProftAverage =
        sumAllInvestments / sumAllInvestmentsWithProft - 1;

      this.averageProfitability = percentageProftAverage.toFixed(4) * 100;
    }
  },
  computed: {
    chartNetInvestiments() {
      const chartNetInvestiments = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '350',
          width: '450'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [],
            dataLabels: { style: { fontSize: '18px' } }
          }
        ],
        colors: ['#4472c4', '#a5a5a5', '#5b9bd5', '#264478']
      };

      const netInvestiment = this.diagnosis.graficos.investimentos_liquidos;

      const dataSeriesChartNetInvestiments = netInvestiment.map((item) => {
        return {
          name: item.nome,
          y: item.valor
        };
      });

      chartNetInvestiments.series[0].data = dataSeriesChartNetInvestiments;

      return chartNetInvestiments;
    },
    items() {
      return this.diagnosis.graficos.investimentos_liquidos;
    }
  },
  mounted() {
    this.calcAvarages();
    this.calcAvarageProfitability();
  }
};
</script>
